import React, { Component } from "react"
import layoutCss from "./layout.module.css"
import Header from "./header/header"
import Footer from "./footer/footer"
import Messenger from "../components/messenger/messenger"
import SocialNav from "./socialNav/socialnav"

class Layout extends Component {
 
  render() {
    return (
      <div className={layoutCss.layout}>
        <Header siteTitle={this.props.siteTitle} />
        <div className={layoutCss.container}>
          <div className={layoutCss.divcontainer}>{this.props.children}</div>
        </div>
        <SocialNav></SocialNav>
        <Messenger></Messenger>
        <Footer className={layoutCss.footer}></Footer>
      </div>
    )
  }
}

export default Layout
