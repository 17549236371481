import React, { Component } from 'react';
import footerCss from "./footer.module.css";

class Footer extends Component {
   

    render() { 
        return ( 
            <footer className={footerCss.footer}>
                <p>{(new Date().getFullYear())} Engrane :: Todos los derechos reservados.</p>
            </footer>
         );
    }
}
 
export default Footer;